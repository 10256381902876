const Keys = {
  ARTICLE: "article",
  BACK_TO_TOP: "back_to_top",
  CONTACT: "contact",
  JOIN_NEWSLETTER: "join_newsletter",
  MENU: "menu",
  CLOSE: "close",
  MORE: "more",
  NEXT: "next",
  PREVIOUS: "previous",
  BACK: 'back',
};

function generateTranslations() {
  const EN = {};
  EN[Keys.ARTICLE] = "Article";
  EN[Keys.BACK_TO_TOP] = "Back to top";
  EN[Keys.CONTACT] = "Contact";
  EN[Keys.JOIN_NEWSLETTER] = "Newsletter coming soon";
  EN[Keys.MENU] = "Menu";
  EN[Keys.CLOSE] = "Close";
  EN[Keys.MORE] = "More";
  EN[Keys.NEXT] = "Next";
  EN[Keys.PREVIOUS] = "Previous";
  EN[Keys.BACK] = "Back";

  const JA = {};
  JA[Keys.ARTICLE] = "Article";
  JA[Keys.BACK_TO_TOP] = "Back to top";
  JA[Keys.CONTACT] = "Contact";
  JA[Keys.JOIN_NEWSLETTER] = "Newsletter coming soon";
  JA[Keys.MENU] = "Menu";
  JA[Keys.CLOSE] = "Close";
  JA[Keys.MORE] = "More";
  JA[Keys.NEXT] = "Next";
  JA[Keys.PREVIOUS] = "Previous";
  JA[Keys.BACK] = "Back";

  return {
    EN,
    JA,
  };
}

const Translations = generateTranslations();

const Localise = (key, languageCode) => {
  if (Translations[languageCode] && Translations[languageCode][key]) {
    return Translations[languageCode][key];
  }

  console.error(`Translation not found for key: ${key}, languageCode: ${languageCode}`);
  return "Not Found";
};

export {Localise, Keys};
