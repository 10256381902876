// extracted by mini-css-extract-plugin
export var active = "Footer-module--active--jojd3";
export var contact = "Footer-module--contact--L8Ifk";
export var copyright = "Footer-module--copyright--p3W1o";
export var footer = "Footer-module--footer--LZE0a";
export var grid = "Footer-module--grid--+JAFl";
export var language = "Footer-module--language--4ixE3";
export var link = "Footer-module--link--4Nj1M";
export var newsletter = "Footer-module--newsletter--uQnnP";
export var scrollToTop = "Footer-module--scrollToTop--P3r3f";
export var section = "Footer-module--section--EJxys";
export var separator = "Footer-module--separator--vOLan";
export var separatorSmall = "Footer-module--separator-small--XYYVR";
export var social = "Footer-module--social--biEbT";