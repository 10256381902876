import React from "react";
import * as styles from "./Footer.module.scss";
import gsap from "gsap";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";
import {Localise, Keys} from "../../utils/translations";
import HomeLink from "../HomeLink";

const Footer = props => {
  const {translations, pageContext} = props;

  let englishLink = "/en/";
  let japaneseLink = "/ja/";

  const {languageCode} = pageContext;

  if (translations) {
    for (const translation of translations) {
      switch (translation.language.code) {
        case "EN":
          englishLink = translation.uri;
          break;
        case "JA":
          japaneseLink = translation.uri;
          break;
        default:
          continue;
      }
    }
  }

  const scrollToTop = () => {
    gsap.registerPlugin(ScrollToPlugin);
    gsap.to(window, 1, {scrollTo: 0});
  };

  return (
    <div className={styles.footer}>
      <hr />
      <div className="grid-container fluid">
        <div className={styles.grid}>
          <div className={[styles.section, styles.newsletter].join(" ")}>
            <button className="button expanded hollow" href="/join-newsletter" disabled={true}>
              {Localise(Keys.JOIN_NEWSLETTER, languageCode)}
            </button>
          </div>
          <div className={[styles.section, styles.contact].join(" ")}>
            <a className="button expanded clear" href="mailto:hello@readaspect.com">
              {Localise(Keys.CONTACT, languageCode)}
            </a>
          </div>
          <div className={styles.separator}></div>
          <div className={[styles.section, styles.social].join(" ")}>
            <a className="button clear" href="https://instagram.com/readaspect">
              IG
            </a>
          </div>
          <div className={styles.separator}></div>
          <div className={[styles.section, styles.language].join(" ")}>
            <HomeLink to={japaneseLink} className={"button clear" + (languageCode === "JA" ? ` ${styles.active}` : "")}>
              JA
            </HomeLink>
            <div className={styles.separatorSmall}></div>
            <HomeLink to={englishLink} className={"button clear" + (languageCode === "EN" ? ` ${styles.active}` : "")}>
              EN
            </HomeLink>
          </div>
          <div className={styles.separator}></div>
          <div className={[styles.section, styles.scrollToTop].join(" ")}>
            <div className="text-center large-text-right">
              <button className="button clear" onClick={scrollToTop}>
                {Localise(Keys.BACK_TO_TOP, languageCode)}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="grid-container fluid text-center large-text-right">
        <div className={styles.copyright}>© Aspect {(new Date().getFullYear())}. All Rights Reserved.</div>
      </div>
    </div>
  );
};

export default Footer;
