import {useEffect} from "react";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

export const useGsapReveal = () => {
  useEffect(() => {
    if (typeof window !== `undefined`) {
      gsap.registerPlugin(ScrollTrigger);
      gsap.core.globals("ScrollTrigger", ScrollTrigger);
    }

    // Initiate reveal
    gsap.utils.toArray(".js-slide-reveal").forEach(elem => {
      gsap.set(elem, {
        x: 0,
        y: "110%",
      });
    });

    // slide reveal batch
    ScrollTrigger.batch(".js-slide-reveal", {
      start: "top bottom-=50px",
      onEnter: batch => {
        gsap.to(batch, {
          y: 0,
          overwrite: true,
          ease: "easeOut",
          duration: 1,
        });
      },
    });

    // Initiate reveal
    gsap.utils.toArray(".js-reveal").forEach(elem => {
      gsap.set(elem, {
        x: 0,
        y: "30px",
        opacity: 0,
      });
    });

    // reveal batch
    ScrollTrigger.batch(".js-reveal", {
      start: "top bottom-=50px",
      onEnter: batch => {
        gsap.to(batch, {
          y: 0,
          opacity: 1,
          overwrite: true,
          ease: "easeOut",
          duration: 1,
        });
      },
    });

    // Initiate reveal
    gsap.utils.toArray(".js-reveal-image").forEach(elem => {
      gsap.set(elem, {
        x: 0,
        y: 0,
        opacity: 0,
        //scale: 0.6,
      });
    });

    // reveal image batch
    ScrollTrigger.batch(".js-reveal-image", {
      start: "top bottom-=50px",
      onEnter: batch => {
        gsap.to(batch, {
          opacity: 1,
          //scale: 1,
          overwrite: true,
          duration: 1,
        });
      },
    });
  }, []);
};
