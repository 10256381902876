import React, { createContext, useRef } from "react";

const NavbarContext = createContext({
  navbarLogoRef: null,
  navbarMenuRef: null,
})

export const NavbarContextProvider = (props) => {
  const navbarLogoRef = useRef(null)
  const navbarMenuRef = useRef(null)

  const context = {
    navbarLogoRef,
    navbarMenuRef
  }

  return <NavbarContext.Provider value={context}>
    {props.children}
  </NavbarContext.Provider>
}

export default NavbarContext;