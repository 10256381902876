// extracted by mini-css-extract-plugin
export var active = "Navbar-module--active--vEI2E";
export var backgroundCurtain = "Navbar-module--background-curtain--K4eV2";
export var decoration = "Navbar-module--decoration--oCh5H";
export var line = "Navbar-module--line--tBDdl";
export var logoWrap = "Navbar-module--logo-wrap--6BigK";
export var menuContainer = "Navbar-module--menu-container--CIDWW";
export var menuOpen = "Navbar-module--menu-open--RFoJT";
export var menuText = "Navbar-module--menu-text--Z-pKW";
export var menuToggleWrap = "Navbar-module--menu-toggle-wrap--P3wjv";
export var navItemsWrap = "Navbar-module--nav-items-wrap--L0YrS";
export var navbar = "Navbar-module--navbar--eORoi";
export var navbarDark = "Navbar-module--navbar-dark--5EYHp";
export var navbarLight = "Navbar-module--navbar-light--Xz0QH";
export var socialItems = "Navbar-module--social-items--DCoQ7";
export var switcher = "Navbar-module--switcher--AW6Jq";
export var textClose = "Navbar-module--text-close--YX8+d";
export var textMenu = "Navbar-module--text-menu--uYLwU";
export var translated = "Navbar-module--translated--xIOR+";