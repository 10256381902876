import React from 'react';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

const YoastSeo = (props) => {
  const {
    lang,
    metaDesc,
    metaKeywords,
    metaRobotsNofollow,
    metaRobotsNoindex,
    opengraphAuthor,
    opengraphDescription,
    opengraphImage,
    opengraphModifiedTime,
    opengraphPublishedTime,
    opengraphPublisher,
    opengraphSiteName,
    opengraphTitle,
    opengraphType,
    opengraphUrl,
    readingTime,
    title,
    twitterDescription,
    twitterImage,
    twitterTitle,
    schema,
    uri,
  } = props;

  const opengraphImageMeta = (siteUrl) => {
    if (siteUrl && opengraphImage?.localFile) {
      return [
        {
          property: 'og:image',
          content: siteUrl + opengraphImage.localFile.publicURL,
        },
        {
          property: 'og:image:width',
          content: opengraphImage.mediaDetails.width,
        },
        {
          property: 'og:image:height',
          content: opengraphImage.mediaDetails.height,
        },
      ];
    }

    return [];
  };

  const twitterImageMeta = (siteUrl) => {
    if (siteUrl && twitterImage?.localFile) {
      return [
        {
          name: 'twitter:image',
          content: siteUrl + twitterImage.localFile.publicURL,
        },
        {
          name: 'twitter:image:width',
          content: twitterImage.mediaDetails.width,
        },
        {
          name: 'twitter:image:height',
          content: twitterImage.mediaDetails.height,
        },
        {
          name: 'twitter:image:alt',
          content: twitterImage.altText,
        },
      ];
    }

    return [];
  };

  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const { site, wp } = data;

        const siteUrl = site.siteMetadata.siteUrl || '';

        const metaDescription =
          metaDesc || wp.generalSettings.description || site.siteMetadata.description;

        const titleText = title
          ? `${title} | ${wp.generalSettings.description || site.siteMetadata.title}`
          : wp.generalSettings.description || site.siteMetadata.title;

        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={titleText}
            titleTemplate={`%s`}
            link={[
              {
                rel: 'canonical',
                href: siteUrl + uri,
              },
            ]}
            meta={[
              {
                name: `description`,
                content: metaDesc,
              },
              {
                property: 'og:author',
                content: opengraphAuthor,
              },
              {
                property: 'og:url',
                content: siteUrl + uri,
              },
              {
                property: `og:title`,
                content: opengraphTitle,
              },
              {
                property: `og:description`,
                content: opengraphDescription,
              },
              {
                property: `og:type`,
                content: opengraphType,
              },

              {
                name: `twitter:card`,
                content: `summary`,
              },
              {
                name: `twitter:creator`,
                content: site.siteMetadata.author,
              },
              {
                name: `twitter:title`,
                content: twitterTitle,
              },
              {
                name: `twitter:description`,
                content: twitterDescription,
              },
              {
                name: `keywords`,
                content: metaKeywords,
              },
            ]
              .concat(opengraphImageMeta(siteUrl))
              .concat(twitterImageMeta(siteUrl))}
          ></Helmet>
        );
      }}
    />
  );
};

const detailsQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
      }
    }
    wp {
      generalSettings {
        title
        description
      }
    }
  }
`;

export default YoastSeo;
