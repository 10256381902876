import React from "react";
import {Link} from "gatsby";

const HomeLink = props => {
  return (
    <Link
      onClick={event => {
        const anchor = event.target.closest("a");

        if (anchor) {
          if (anchor.pathname === window.location.pathname) {
            event.preventDefault();
          }
        }
      }}
      {...props}
    >
      {props.children}
    </Link>
  );
};

export default HomeLink;
