import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { graphql, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
// import { Localise, Keys } from "../../utils/translations";
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import gsap from 'gsap';
import * as styles from './Navbar.module.scss';
import NavbarContext from '../../store/navbar-context';
import HomeLink from '../HomeLink';

const Navbar = (props) => {
  const data = useStaticQuery(graphql`
    query MetadataQuery {
      site {
        siteMetadata {
          title
        }
      }
      artArchiveEn: wpPage(
        language: { code: { eq: EN } }
        template: { templateName: { eq: "Art Archive" } }
      ) {
        id
        title
        uri
      }
      artArchiveJa: wpPage(
        language: { code: { eq: JA } }
        template: { templateName: { eq: "Art Archive" } }
      ) {
        id
        title
        uri
      }
    }
  `);

  const { pageContext, style } = props;
  const { languageCode } = pageContext;

  const navbarContext = useContext(NavbarContext);

  const [menuAnimation, setMenuAnimation] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    gsap.registerPlugin(ScrollToPlugin);
    const animation = new gsap.timeline({
      repeat: 0,
      paused: true,
      onStart: () => {
        const el = document.querySelector(`.${styles.menuToggleWrap}`);
        el.classList.add(styles.menuOpen);
      },
      onReverseComplete: () => {
        const el = document.querySelector(`.${styles.menuToggleWrap}`);
        el.classList.remove(styles.menuOpen);
      },
    });
    animation.to(`.${styles.menuContainer}`, 0, {
      display: 'block',
    });
    animation.to(`.${styles.backgroundCurtain}`, 0.75, {
      scale: 1,
      transformOrigin: "50% 0%",
      borderRadius: "100%",
      easing: "power2.in",
    });
    animation.to(
      `span.${styles.decoration}`,
      0.55,
      {
        width: '100%',
      },
      '-=0.55',
    );
    animation.to(`.${styles.backgroundCurtain}`, 0.5, {borderRadius: "0%", easing: "power2.out"}, "-=0.15");
    animation.to(`.${styles.navItemsWrap}`, 0.5, {opacity: 1});

    setMenuAnimation(animation);
  }, []);

  let englishLink = '/en/';
  let japaneseLink = '/ja/';
  let homePageLink = englishLink;
  if (pageContext?.languageCode === 'JA') {
    homePageLink = japaneseLink;
  
  }

  const menuButtonClickHandler = (event) => {
    event.preventDefault();

    if (menuOpen) {
      menuAnimation.reverse();
      setMenuOpen(false);
    } else {
      menuAnimation.play();
      setMenuOpen(true);
    }
  };

  const pageLinkClickHandler = (event) => {
    if (event.target.pathname === window.location.pathname) {
      var targetEl = document.querySelector(event.target.hash);
      if (targetEl) {
        event.preventDefault();
        const targetRect = targetEl.getBoundingClientRect();
        gsap.to(window, {
          scrollTo: (window.pageYOffset || document.documentElement.scrollTop) + targetRect.top,
          ease: 'power2.inOut',
          duration: 0.4,
        });
      }
    }
  };

  const renderArtArchiveLink = () => {
    if( data.artArchiveEn && data.artArchiveJa ){
      const artArchive = pageContext?.languageCode === 'JA' ? data.artArchiveJa : data.artArchiveEn;
      return(
        <li>
          <Link
          className="secondary-font"
          to={artArchive.uri}>{artArchive.title}

          </Link>
        </li>
      )
    }
    return null;
  }


  const renderLogo = () => {
    if (style === 'dark') {
      return (
        <StaticImage
          src="../../assets/images/logo-dark.png"
          alt={data.site.siteMetadata.title}
          height={40}
          placeholder="none"
        />
      );
    }
    return (
      <StaticImage
        src="../../assets/images/logo.png"
        alt={data.site.siteMetadata.title}
        height={40}
        placeholder="none"
      />
    );
  };

  return (
    <div
      className={[
        'site-navbar',
        styles.navbar,
        style === 'dark' ? styles.navbarDark : styles.navbarLight,
      ].join(' ')}
    >
      <div className={[styles.logoWrap, 'logo-wrap'].join(' ')} ref={navbarContext.navbarLogoRef}>
        <HomeLink to={homePageLink} className="logo-anchor">
          {renderLogo()}
        </HomeLink>
      </div>

      <div
        className={[styles.menuToggleWrap, 'menu-toggle-wrap'].join(' ')}
        ref={navbarContext.navbarMenuRef}
      >
        <span className={styles.decoration}>
          <span className={styles.line}></span>
        </span>
        <button href="#" onClick={menuButtonClickHandler}>
          <span className={styles.menuText}>
            <span className={styles.translated}>
              <span className={styles.textMenu}>Menu</span>
              <span className={styles.textClose}>Close</span>
              {/* {menuOpen ? Localise(Keys.CLOSE, pageContext?.languageCode) : Localise(Keys.MENU, pageContext?.languageCode)} */}
            </span>
          </span>
        </button>
      </div>

      <div className={styles.menuContainer}>
        <div className={styles.backgroundCurtain}></div>
        <div className={styles.navItemsWrap}>
          <ul>
            <li>
              <HomeLink to={homePageLink} className="secondary-font">
                Home
              </HomeLink>
              <div className={styles.switcher}>
                <HomeLink
                  className={languageCode === 'JA' ? ` ${styles.active}` : ''}
                  to={japaneseLink}
                >
                  JA
                </HomeLink>{' '}
                |{' '}
                <HomeLink
                  className={languageCode === 'EN' ? ` ${styles.active}` : ''}
                  to={englishLink}
                >
                  EN
                </HomeLink>
              </div>
            </li>
            {/* <li>
              <Link to={`${homePageLink}#about-us`} className="secondary-font" onClick={pageLinkClickHandler}>
                About Aspect
              </Link>
            </li> */}
            <li>
              <Link
                to={`${homePageLink}#journal`}
                className="secondary-font"
                onClick={pageLinkClickHandler}
              >
                Articles
              </Link>
            </li>
            {/* <li>
              <a href="#join-newsletter" className="secondary-font">
                Join Newsletter
              </a>
            </li> */}
            {/* <li>
              <a href="mailto:hello@readaspect.com" className="secondary-font">
                Contact
              </a>
            </li> */}
            {renderArtArchiveLink()}
            <li>
              <span className="secondary-font">Social</span>
              <div className={styles.socialItems}>
                <ul>
                  <li>
                    <a href="https://instagram.com/readaspect" target="_blank" rel="noreferrer">
                      IG
                    </a>
                  </li>
                  {/* <li>
                    <a href="#">FB</a>
                  </li>
                  <li>
                    <a href="#">TW</a>
                  </li> */}
                </ul>
              </div>
            </li>
            <li>
              <a
                className="secondary-font"
                href="https://tokyobike.com"
                target="_blank"
                rel="noreferrer"
              >
                Tokyobike
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

Navbar.propTypes = {
  style: PropTypes.string.isRequired,
};

export default Navbar;
