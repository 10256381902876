import React from "react";
import PropTypes from 'prop-types'
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer/Footer";
import {Helmet} from "react-helmet";
import {useGsapReveal} from "../utils/useGsapReveal";
import {NavbarContextProvider} from "../store/navbar-context";

const Layout = props => {
  useGsapReveal();

  const {pageContext, navbarStyle, bodyClass, hideFooter} = props;

  let bodyLangClass = "lang-en";

  if (pageContext.languageCode === "JA") {
    bodyLangClass = "lang-ja";
  }

  return (
    <NavbarContextProvider>
      <Helmet bodyAttributes={{class: `${bodyClass} ${bodyLangClass}`}}></Helmet>
      <Navbar pageContext={pageContext} style={navbarStyle} />
      {props.children}
      {!hideFooter && <Footer translations={props.translations} pageContext={pageContext} />}
    </NavbarContextProvider>
  );
};

Layout.defaultProps = {
  navbarStyle: 'light',
  hideFooter: false
}
Layout.propTypes = {
  hideFooter: PropTypes.bool.isRequired,
  navbarStyle: PropTypes.string // light, dark
}
export default Layout;
